import { Env } from './environment.model';
export const environment: Env = {
  production: true,
  disableLogs: false,
  auth: {
    isLocalLoginEnabled: false,
    redirectUrl: 'https://www.rc.radioking.com/login?redirect=https:%2F%2Fmobile.rc.radioking.com',
  },
  urls: {
    MAIN_API: 'https://api.rc.radioking.io',
    MOBILE_API: 'https://www.rc.radioking.com/mobile/api',
    RADIOKING_DOMAIN: 'rc.radioking.com',
    SIMULATOR: 'https://mobile-simulator.rc.radioking.com'
  },
  whmcs: {
    url: 'https://www.rc.radioking.com/on',
    start: {
      customFieldId: '137',
      planId: '53',
      publicationAddonId: '17',
    },
    pro: {
      customFieldId: '7',
      planId: '7',
      publicationAddonId: '17',
    },
    business: {
      customFieldId: '139',
      planId: '55',
      publicationAddonId: '19',
    },
  },
  googleMapsKey: 'AIzaSyA1BNLwxFxrbnVeH5Er5387UFI0G2xeJjQ',
  gtm: 'GTM-PVP6SKT',
  amplitudeKey: '407a767ecf74ff8653c1d8d2822237af',
  cookies: {
    config: {
      domain: '.rc.radioking.com',
      expires: 365,
      secure: true,
      path: '/',
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
};
